import React, { useState, FC } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import MainTemplate from '../components/MainTemplate';
import Button from '@mui/material/Button';
import Input from '../components/Input';
import { useTheme } from '@mui/material';
import { PhoneNumberInput } from '../components/Input';
import { PageProps, SetUserDataValue, UserData } from '../App';

interface LandingProps {
  setUserData: (value: UserData | SetUserDataValue) => void;
}

type InputType = 'email' | 'phone';

const Landing: FC<PageProps & LandingProps> = ({
  setPage,
  userData,
  setUserData,
  startExperience
}) => {
  const [error, setError] = useState('Invalid phone number');
  const [isTouched, setIsTouched] = useState(false);
  const [inputType, setInputType] = useState<InputType>('phone');
  const theme = useTheme();

  const submitHandler = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    setIsTouched(true);

    if (error) {
      return;
    }

    const res = await startExperience();

    if (!res) return;

    if ('status' in res && res.status === 200) {
      setPage('started');
    } else {
      setPage('register');
    }
  };

  const phoneNumberHandler = (value: string, data: any) => {
    setUserData((prev: UserData) => ({ ...prev, phone: value }));
    const correctLength = data.format.match(/\./g).length;
    if (correctLength !== value.length) {
      setError('Invalid phone number');
    } else {
      setError('');
    }
  };

  const emailHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUserData((prev: UserData) => ({ ...prev, email: event.target.value }));
  };

  return (
    <MainTemplate>
      <Button
        variant="text"
        sx={{
          position: 'absolute',
          right: '10px',
          top: '10px',
          textTransform: 'none'
        }}
        onClick={() => {
          setUserData({ email: '', phone: '' });
          if (inputType === 'phone') {
            setError('');
            setInputType('email');
          } else {
            setInputType('phone');
          }
        }}
      >
        {inputType === 'phone' ? 'Or email?' : ' Or phone?'}
      </Button>
      <Box>
        <Typography
          variant="subtitle1"
          sx={{
            marginBottom: '30px',
            [theme.breakpoints.down('sm')]: {
              padding: '0 30px'
            }
          }}
        >
          Enter your {inputType === 'phone' ? ' phone number' : 'email'} so we
          can get started finding your fit!
        </Typography>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            textAlign: 'center'
          }}
        >
          <form onSubmit={submitHandler}>
            {inputType === 'phone' ? (
              <PhoneNumberInput
                label="Phone"
                value={userData.phone}
                handleChange={phoneNumberHandler}
                isTouched={isTouched}
                setIsTouched={setIsTouched}
                sx={{ marginBottom: 2 }}
                error={!!error}
                helperText={error}
              />
            ) : (
              <Input
                value={userData.email}
                onChange={emailHandler}
                label="Email"
                name="email"
                sx={{ marginBottom: 2 }}
                type="email"
                required
              />
            )}
            <Button
              type="submit"
              sx={{ marginBottom: 2, background: '#fff' }}
              color="inherit"
              variant="outlined"
            >
              Continue in App
            </Button>
          </form>
          {userData.phone ? (
            <Typography variant="subtitle2">SMS charges may apply</Typography>
          ) : null}
        </Box>
      </Box>
    </MainTemplate>
  );
};

export default Landing;
